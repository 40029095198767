
body {
  width: 100%;
  height: 100vh;
  background-color: #eee !important;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}


.scroll{
  height: 80vh;
  overflow: auto;
}

.message{
  max-width: 250px;
}

.chat{
  width: 80%;
  margin-left: 10vw;
  margin-right: 10vw;
}  